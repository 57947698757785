import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Loadable from "@loadable/component"
import Seo from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'

import { LightningBoltIcon, ClipboardCheckIcon, SupportIcon, DocumentSearchIcon } from '@heroicons/react/outline'

const CloudLottie = Loadable(() => import('../components/cloud-lottie'))
const RobotLottie = Loadable(() => import('../components/robot-lottie'))

const code_image = '../images/code.png'
const lottie_image_size = { height: 320, width: 320 }

export default function Home() {
  return (
  	<>
  		<Seo title="We Do Everything Cloud" />
	  	<Layout>
	  		<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
	        <div className="sm:text-center lg:text-left">
	        	<h1 className="text-skyblue text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pb-4">Pyroclastic Cloud</h1>
	          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
	            <span className="block xl:inline">Delivering innovation and cloud solutions for your</span>{' '}
	            <span className="block text-skyblue xl:inline">online business</span>
	          </h2>
	          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
	            From monoliths, to microservices, to lambda and back again, Pyroclastic Cloud is here to help you decide, design and build your next application for cloud.
	          </p>
	          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
	            <div className="rounded-md shadow">
	              <Link
	                to="/contact"
	                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkskyblue hover:bg-skyblue md:py-4 md:text-lg md:px-10"
	              >
	                Contact Us
	              </Link>
	            </div>
	          </div>
	        </div>
	      </main>
	  	</Layout>
	  	<section className="bg-lightskyblue py-16 md:py-20">
	  		<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
	  			<div className="flex items-center flex-wrap">
	  				<div className="w-full lg:w-1/2 order-last lg:order-first">
	  					<div className="rounded-lg overflow-hidden my-10 lg:my-0 h-80 w-80 mx-auto">
	  						<CloudLottie />
	  					</div>
	  				</div>
	  				<div className="w-full lg:w-1/2">
	  					<h3 className="text-3xl text-center text-gray-500 pb-6">We Do Everything Cloud</h3>
	  					<p className="text-xl text-charcoal font-light py-4">
	  						We practice consulting with a "container first" attitude. Let us answer your technical questions about "everything cloud". Why do companies benefit from cloud? What is IoT? Serverless?
	  					</p>
	  					<ul className="ml-10 text-xl text-charcoal font-light py-4 list-disc">
	  						<li className="py-1">Web Applications</li>
	  						<li className="py-1">Marketing Websites</li>
	  						<li className="py-1">SEO Optimization</li>
	  						<li className="py-1">Containerized Microservices</li>
	  						<li className="py-1">Cloud Lambda Functions</li>
	  						<li className="py-1">Real-time Communications</li>
	  						<li className="py-1">Business Processing Pipelines</li>
	  						<li className="py-1">Custom API Inegrations</li>
	  						<li className="py-1">Bulk Document Processing</li>
	  						<li className="py-1">IoT Deployment/Automation</li>
	  						<li className="py-1">Serverless Architecture</li>
	  					</ul>
	  				</div>
	  			</div>
	  		</div>
      </section>
      <section id="business-pro" className="bg-skyblue text-gray-900 body-font py-16 md:py-20">
      	<div className="px-5 flex flex-col text-center w-full mb-16 md:mb-20">
		      <h2 className="text-sm text-charcoal tracking-widest font-medium title-font mb-1">BECOME A PRO</h2>
		      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Now Featuring Business Website Hosting</h1>
		      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Pyroclastic now offers a basic website package known as "Business Pro". This offer is for businesses operating on a budget and will enable your business to save money but still deliver a lightning fast website with a crisp, clean user interface experience.</p>
		    </div>
			  <div className="container px-5 mx-auto flex flex-wrap">
			    <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden grid justify-items-center">
			      <StaticImage alt="feature" width={560} className="mt-6 object-center" src="../images/lighthouse-score.png" />
			    </div>
			    <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
			      <div className="flex flex-col mb-10 lg:items-start items-center">
			        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full border-white border bg-darkskyblue text-lightskyblue mb-5">
			          <LightningBoltIcon className="h-6 w-6" aria-hidden="true" />
			        </div>
			        <div className="flex-grow">
			          <h2 className="text-white text-lg title-font font-medium mb-3">Performance</h2>
			          <p className="leading-relaxed text-base">We put speed at the forefront so that your users don't have to wait for information. We boast load times of less than 3 seconds. Most creative agencies neglect this metric and have scores in the red.</p>
			          <a href="/" className="mt-3 text-lightskyblue inline-flex items-center invisible">Learn More
			            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
			              <path d="M5 12h14M12 5l7 7-7 7"></path>
			            </svg>
			          </a>
			        </div>
			      </div>
			      <div className="flex flex-col mb-10 lg:items-start items-center">
			        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full border-white border bg-darkskyblue text-lightskyblue mb-5">
			          <SupportIcon className="h-6 w-6" aria-hidden="true" />
			        </div>
			        <div className="flex-grow">
			          <h2 className="text-white text-lg title-font font-medium mb-3">Accessibility</h2>
			          <p className="leading-relaxed text-base">Does your website adhere to section 508 accessibility compliance legislation? Ours do. Sometimes our users cannot see and thusly rely on screen reading apps for assistance.</p>
			          <a href="/" className="mt-3 text-lightskyblue inline-flex items-center invisible">Learn More
			            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
			              <path d="M5 12h14M12 5l7 7-7 7"></path>
			            </svg>
			          </a>
			        </div>
			      </div>
			      <div className="flex flex-col mb-10 lg:items-start items-center">
			        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full border-white border bg-darkskyblue text-lightskyblue mb-5">
			          <ClipboardCheckIcon className="h-6 w-6" aria-hidden="true" />
			        </div>
			        <div className="flex-grow">
			          <h2 className="text-white text-lg title-font font-medium mb-3">Best Practices</h2>
			          <p className="leading-relaxed text-base">Since we invented the wheel and then re-invented it... Well just remember, WE ARE "everything cloud". Bring your best practices to the table. Well, we have covered here.</p>
			          <a href="/" className="mt-3 text-lightskyblue inline-flex items-center invisible">Learn More
			            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
			              <path d="M5 12h14M12 5l7 7-7 7"></path>
			            </svg>
			          </a>
			        </div>
			      </div>
			      <div className="flex flex-col mb-10 lg:items-start items-center">
			        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full border-white border bg-darkskyblue text-lightskyblue mb-5">
			          <DocumentSearchIcon className="h-6 w-6" aria-hidden="true" />
			        </div>
			        <div className="flex-grow">
			          <h2 className="text-white text-lg title-font font-medium mb-3">SEO</h2>
			          <p className="leading-relaxed text-base">Last but in no way least. We want all search engines and people to find your business on the web. Whether it be social or page lead generation, we guarantee your business will be optimized.</p>
			          <a href="/" className="mt-3 text-lightskyblue inline-flex items-center invisible">Learn More
			            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
			              <path d="M5 12h14M12 5l7 7-7 7"></path>
			            </svg>
			          </a>
			        </div>
			      </div>
			    </div>
			    <div className="w-full py-16 md:py-20 flex justify-center">
			    	<div className="mt-3 sm:mt-0 sm:ml-3">
	            <a
	              href="https://pyro-business-pro.netlify.app/"
	              target="_blank"
	              rel="noreferrer"
	              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-darkskyblue bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
	            >
	              Try Our Demo
	            </a>
	          </div>
			    </div>
			  </div>
			</section>
      <section className="py-16 md:py-20">
      	<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      		<div className="flex items-center flex-wrap">
	  				<div className="w-full lg:w-1/2">
	  					<h3 className="text-3xl text-center text-gray-500 pb-6">
	  						<span className="block">Serverless IoT</span>
	  						<span className="block text-gray-400 text-2xl">The Next Generation</span>
	  					</h3>
	  					<p className="text-xl text-charcoal font-light py-4">
	  						What? All you need to know is that this is the "next big thing" in cloud. But why? The short answer is that not only does a business need to worry about consumer traffic, they also need to worry about IoT traffic. IoT, Internet of Things, traffic consists of bot communications to drive business processes. These devices interface with consumers in a "serverless" architecture. These architectures are mainly comprised of microservices and lambda functions that support business logic processing from consumers and bots.
	  					</p>
	  					<p className="text-xl text-charcoal font-light py-4">
	  						Still confused? Need not worry. We are able to help transition your company to it's "next generation".
	  					</p>
	  				</div>
	  				<div className="w-full lg:w-1/2">
	  					<div className="overflow-hidden my-10 lg:my-0 h-80 w-80 mx-auto">
	  						<RobotLottie />
	  					</div>
	  				</div>
	  			</div>
	  		</div>
      </section>
      <section className="bg-lightskyblue py-16 md:py-20">
      	<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      		<div className="flex items-center flex-wrap">
      			<div className="w-full lg:w-1/2 order-last lg:order-first">
	  					<div className="rounded-lg overflow-hidden my-10 lg:my-0 h-80 w-80 mx-auto">
	  						<StaticImage {...lottie_image_size} src={code_image} alt="Pyroclastic Lambda Code" />
	  					</div>
	  				</div>
	  				<div className="w-full lg:w-1/2">
	  					<h3 className="text-3xl text-center text-gray-500 pb-6">Cloud Lambda vs Cloud Microservices</h3>
	  					<p className="text-xl text-charcoal font-light py-4">
	  						Don't worry because we didn't put this here to confuse you. As your company transitions from small to large, Pyroclasticloud will help you build each container to suit your needs. Already a large company?
	  					</p>
	  					<p className="text-xl text-charcoal font-light py-4">
	  						We can help you break down your monoliths into microservices and lambda functions in cloud. What about your IoT devices? We can automate updates and deployment with those systems too. We can make what seems complex and make that very simple.
	  					</p>
	  				</div>
	  			</div>
	  		</div>
      </section>
      <Footer />
	  </>
  )
}
